<template>
  <section style="padding: 20pt">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :height="55"
      :width="65"
      loader="bars"
      :opacity="0.7"
    ></loading>
    <br />
    <h1 align="center" class="title">Add Snacks 🥪</h1>
    <p align="center">
      <a
        href="https://console.firebase.google.com/u/1/project/snax-dde4e/firestore/data~2Fsnacks"
        >open database</a
      ><br />find products <a href="https://www.barcodelookup.com/">here</a>,
      just search for brands. Make sure the products you find have a UPC code
      not the EAN code, and don't enter bulk items (like 24 packs of chips).
    </p>

    <p style="color: orange">
      You must have
      <a
        href="https://chrome.google.com/webstore/detail/cors-unblock/lfhmikememgdcahcdlaciloancbhjino/related"
        >this</a
      >
      extension installed and enabled to search/submit anything.
    </p>
    <br /><br />
    <div align="left">
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">ID</label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control">
              <input
                spellcheck="false"
                class="input"
                v-model="id"
                @blur="idSearch"
                v-on:keyup.enter="idSearch"
                type="text"
                placeholder="ex: pringles-low-fat"
              />
              <small>Don't use special characters or spaces.</small>
            </p>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Name</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div
              v-for="(result, index) in upcResults"
              :key="index"
              class="tags has-addons"
              @click="selectUpcResult(index)"
              style="
                margin-bottom: 5pt;
                margin-right: 5pt;
                border: none;
                cursor: pointer;
                display: inline-block;
              "
            >
              <span class="tag is-info is-light">{{ result["code"] }}</span>
              <span class="tag is-info"
                ><b>{{ result["product_name"] }}</b></span
              >
            </div>
            <p :class="{ 'is-loading': upcFetching }" class="control">
              <input
                class="input"
                v-model="name"
                type="text"
                placeholder="Pringles (Low Fat Flavor)"
              />
            </p>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">UPC Code</label>
        </div>
        <div class="field-body">
          <div class="field">
            <p :class="{ 'is-loading': upcFetching }" class="control">
              <input
                class="input"
                v-model="upc"
                type="number"
                placeholder="012345678910"
              />
            </p>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Type</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <div class="select">
                <select v-model="type">
                  <option value="candy">Candy</option>
                  <option value="candy-bar">Candy Bar</option>
                  <option value="chip">Chips</option>
                  <option value="concessions">Concessions</option>
                  <option value="cookie">Cookie</option>
                  <option value="cracker">Crackers</option>
                  <option value="pastry">Pastries</option>
                  <option value="puffs">Puffs</option>
                  <option value="spread">Spreads & Dips</option>
                  <option value="snack-mix">Snack Mix</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Image</label>
        </div>
        <div class="field-body">
          <div class="field" style="margin-top: 5pt">
            Searches based on snack id.
            <b>Results: {{ imageResults.length }}</b>
            &nbsp;
            <span class="icon" v-if="imageFetching">
              <i class="fas fa-sync fa-pulse"></i>
            </span>
            <br />
            <div
              v-for="(result, index) in imageResults"
              :class="{ 's-image-selected': index == selectedImage }"
              :style="{
                'background-image': 'url(https:' + result.thumbnail + ')',
              }"
              class="s-image-square"
              style=""
              :key="index"
              @click="selectImage(index)"
              onerror="this.style.display='none'"
            />
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Banner</label>
        </div>
        <div class="field-body">
          <div class="field" style="margin-top: 5pt">
            Generates based on chosen snack image<br />
            <div style="margin-top: 10pt" class="buttons has-addons">
              <button @click="changeTolerance(-1)" class="button">-</button>
              <button disabled class="button">
                Tolerance: {{ wandTolerance }}
              </button>
              <button @click="changeTolerance(1)" class="button">+</button>
            </div>
            <div
              v-if="selectedImage != null"
              style="
                height: 70pt;
                margin-top: 10pt;
                overflow: none;
                width: 100%;
              "
            >
              <div class="s-banner-wand-preview">
                <span>Original</span>
                <img
                  style="max-width: 100%; max-height: 100%"
                  id="wand-input"
                  @load="generateBanners"
                  :src="imageResults[selectedImage].thumbnail"
                />
              </div>
              <div class="s-banner-wand-preview">
                <span>No Background</span>
                <img
                  style="max-width: 100%; max-height: 100%"
                  id="wand-clipped"
                />
              </div>
              <div class="s-banner-wand-preview">
                <span>Padding Fix</span>
                <img
                  style="max-width: 100%; max-height: 100%"
                  id="wand-nopad"
                />
              </div>
              <div class="s-banner-wand-preview">
                <span>Final Squared</span>
                <img
                  style="max-width: 100%; max-height: 100%"
                  id="wand-output"
                />
              </div>
            </div>
            <div
              v-if="bannerColorResults.length > 0"
              style="margin-top: 10pt; width: 100%"
            >
              <div
                v-for="(color, index) in bannerColorResults"
                :key="index"
                :style="{
                  background: `linear-gradient(0deg, ${color[0]} 0%, ${color[1]} 100%)`,
                }"
                class="s-banner-color-preview"
                @click="selectBannerColor(index)"
              />
              <div
                v-if="bannerColorSelected != null"
                class="s-banner-previews"
                style="margin-top: 10pt; width: 100%"
              >
                <banner1
                  id="banner-1"
                  @click.native="selectBanner(1)"
                  :class="{ 's-banner-selected': selectedBanner == 1 }"
                  :color="bannerColorResults[bannerColorSelected]"
                  :src="bannerWandOutput"
                />
                <banner2
                  id="banner-2"
                  @click.native="selectBanner(2)"
                  :class="{ 's-banner-selected': selectedBanner == 2 }"
                  :color="bannerColorResults[bannerColorSelected]"
                  :src="bannerWandOutput"
                />
                <banner3
                  id="banner-3"
                  :class="{ 's-banner-selected': selectedBanner == 3 }"
                  @click.native="selectBanner(3)"
                  :color="bannerColorResults[bannerColorSelected]"
                  :src="bannerWandOutput"
                />
                <banner4
                  id="banner-4"
                  :class="{ 's-banner-selected': selectedBanner == 4 }"
                  @click.native="selectBanner(4)"
                  :color="bannerColorResults[bannerColorSelected]"
                  :src="bannerWandOutput"
                />
                <banner5
                  id="banner-5"
                  :class="{ 's-banner-selected': selectedBanner == 5 }"
                  @click.native="selectBanner(5)"
                  :color="bannerColorResults[bannerColorSelected]"
                  :src="bannerWandOutput"
                />
                <banner6
                  id="banner-6"
                  :class="{ 's-banner-selected': selectedBanner == 6 }"
                  @click.native="selectBanner(6)"
                  :color="bannerColorResults[bannerColorSelected]"
                  :src="bannerWandOutput"
                />
                <banner7
                  id="banner-7"
                  @click.native="selectBanner(7)"
                  :class="{ 's-banner-selected': selectedBanner == 7 }"
                  :color="bannerColorResults[bannerColorSelected]"
                  :src="bannerWandOutput"
                />
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label"></label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control">
              <label class="checkbox">
                <input type="checkbox" v-model="onlyImage" />
                Only Update Images
              </label>
            </p>
          </div>
        </div>
      </div>
      <br />
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label style="color: #276cda" class="label">Password</label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control">
              <input class="input" v-model="password" type="password" />
            </p>
          </div>
        </div>
      </div>
      <br /><br />
      <p align="center">
        <button
          id="submit"
          @click="submitToDatabase"
          :class="{ 'is-loading': isLoading }"
          class="button is-link is-medium"
        >
          send it to the mainframe
        </button>
      </p>
      <br />
    </div>
  </section>
</template>

<script>
import * as firebase from "firebase/app";
import "firebase/storage";
import "firebase/auth";
import "regenerator-runtime";
import CanvasMagicWand from "canvas-magic-wand";
import ColorThief from "colorthief";
const grad = require("gradient-from-image");
import trimImageData from "trim-image-data";
import domtoimage from "dom-to-image";

//import { open, setTitle } from 'tauri/api/window'

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import banner1 from "../components/banner1.vue";
import banner2 from "../components/banner2.vue";
import banner3 from "../components/banner3.vue";
import banner4 from "../components/banner4.vue";
import banner5 from "../components/banner5.vue";
import banner6 from "../components/banner6.vue";
import banner7 from "../components/banner7.vue";

export default {
  name: "Home",
  components: {
    Loading,
    banner1,
    banner2,
    banner3,
    banner4,
    banner5,
    banner6,
    banner7,
  },
  data() {
    return {
      /** @type {string} */
      id: null,
      name: null,
      upc: null,
      type: null,
      password: null,
      imageResults: [],
      selectedImage: null,
      isLoading: false,
      upcResults: [],
      upcFetching: false,
      bannerWandOutput: null,
      bannerColorResults: [],
      bannerColorSelected: null,
      imageFetching: false,
      lastIdQuery: null,
      wandTolerance: 2,
      onlyImage: false,
      selectedBanner: null,
    };
  },
  watch: {
    id() {
      if (this.id != null) {
        this.id = this.id.split(" ").join("-").toLowerCase();
      }
    },
  },
  methods: {
    selectImage(index) {
      this.selectedImage = index;
    },
    selectUpcResult(index) {
      if (this.upcResults.length > index) {
        let product = this.upcResults[index];
        if ("code" in product && product["code"].length >= 12) {
          this.upc = product["code"].substr(-12);
        } else {
          this.upc = null;
        }
        this.name = product["product_name"];
      }
    },
    selectBannerColor(index) {
      this.bannerColorSelected = index;
    },
    selectBanner(index) {
      console.log("clicked banner ", index);
      this.selectedBanner = index;
    },
    changeTolerance(num) {
      this.wandTolerance += num;
      if (this.wandTolerance < 0) this.wandTolerance++;
      else if (this.wandTolerance > 100) this.wandTolerance--;

      if (this.bannerWandOutput != null) {
        this.generateBanners();
      }
    },
    generateBanners() {
      console.log("TIME TO GENERATE BANNERS");

      const input = document.querySelector("#wand-input");
      const clipped = document.querySelector("#wand-clipped");
      const nopad = document.querySelector("#wand-nopad");
      const output = document.querySelector("#wand-output");

      input.crossOrigin = "Anonymous";

      console.log(input["src"]);

      CanvasMagicWand.knockoutColor({
        img: input,
        // src
        // targetColor: `#ffffff`,
        // replacementColor: '#ffffff00',
        tolerance: this.wandTolerance,
        // edgeBlur: 4,
        // edgeBrightness: 2,
        edgeContrast: 50,
        // debugMode: false,
      }).then(({ src }) => {
        //Cutout image
        clipped.src = src;

        //Remove excess transparency
        var canvas = document.createElement("canvas");
        var context = canvas.getContext("2d");
        var img = clipped;
        canvas.width = 200;
        canvas.height = 200;
        context.drawImage(img, 0, 0);
        var myData = context.getImageData(0, 0, 200, 200);
        let trimmed = trimImageData(myData);
        //new canvas
        canvas = document.createElement("canvas");
        context = canvas.getContext("2d");
        canvas.width = trimmed.width;
        canvas.height = trimmed.height;
        context.putImageData(trimmed, 0, 0);
        var dataURLnoPad = canvas.toDataURL("image/png");

        nopad.src = dataURLnoPad;

        //Square off
        canvas = document.createElement("canvas");
        context = canvas.getContext("2d");
        let mainSize =
          trimmed.width > trimmed.height ? trimmed.width : trimmed.height;
        canvas.width = mainSize;
        canvas.height = mainSize;
        context.putImageData(
          trimmed,
          mainSize / 2 - trimmed.width / 2,
          mainSize / 2 - trimmed.height / 2
        );
        var dataURL = canvas.toDataURL("image/png");

        this.bannerWandOutput = dataURL;
        output.src = dataURL;

        //Get Colors
        const colorThief = new ColorThief();
        this.bannerColorResults = colorThief
          .getPalette(clipped)
          .map((e) => [
            this.rgbToHex(e[0], e[1], e[2]),
            this.rgbToHex(e[0], e[1], e[2]),
          ]);
        this.bannerColorResults = this.bannerColorResults.concat(
          colorThief
            .getPalette(clipped)
            .map((e) => [
              this.rgbToHex(e[0], e[1], e[2]),
              this.getRandomColor(this.rgbToHex(e[0], e[1], e[2])),
            ])
        );
        grad.gr(src).then((gradients) => {
          // this will gives you array of gradients
          //change this is to element css el.background="` linear-gradient(${gradient})`"
          console.log(gradients);
          this.bannerColorResults.push(gradients.vibrant);
          this.bannerColorResults.push(gradients.relevant);
          console.log(this.bannerColorResults);
        });
      });
    },
    getRandomColor(color) {
      var p = 1,
        temp,
        random = Math.random(),
        result = "#";

      while (p < color.length) {
        temp = parseInt(color.slice(p, (p += 2)), 16);
        temp += Math.floor((255 - temp) * random);
        result += temp.toString(16).padStart(2, "0");
      }
      return result;
    },
    componentToHex(c) {
      var hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    },
    rgbToHex(r, g, b) {
      return (
        "#" +
        this.componentToHex(r) +
        this.componentToHex(g) +
        this.componentToHex(b)
      );
    },
    idSearch() {
      var _this = this;
      if (
        this.id != null &&
        this.id.trim() != "" &&
        this.id != this.lastIdQuery
      ) {
        this.lastIdQuery = this.id;
        //Reset Stuff
        this.bannerColorResults = [];
        this.bannerColorSelected = null;
        this.bannerWandOutput = null;
        this.selectedBanner = null;
        //SEARCH FOR IMAGES
        this.imageFetching = true;
        this.selectedImage = null;
        this.imageResults = [];
        $.ajax({
          url: "https://api.qwant.com/api/search/images",
          type: "GET",
          data: {
            count: "15",
            q: this.id.trim().split("-").join(" "),
            t: "images",
            safesearch: "1",
            locale: "en_US",
            uiv: "4",
          },
        })
          .done(function (data, textStatus, jqXHR) {
            console.log("HTTP Request Succeeded: " + jqXHR.status);
            console.log(data);
            if (data["status"] == "success") {
              console.log("setting image");
              _this.$nextTick(() => {
                _this.imageResults = data["data"]["result"]["items"];
              });
            }
          })
          .fail(function (jqXHR, textStatus, errorThrown) {
            console.log("HTTP Request Failed", jqXHR.status);
            if (jqXHR.status == 429) {
              //You have to do a captcha
              //open("https://www.qwant.com/?q="+_this.id.split("-").join("%20")+"&t=web")
              console.log("please open new tab");
              window.open(
                "https://www.qwant.com/?q=" +
                  _this.id.split("-").join("%20") +
                  "&t=web"
              );
            }
          })
          .always(function () {
            _this.imageFetching = false;
          });

        //Clear previous
        this.upcResults = [];

        //Don't need this when onlyImage is enabled
        if (this.onlyImage == true) return;

        //SEARCH FOR UPC CODES
        this.upcFetching = true;

        $.ajax({
          url: "https://us.openfoodfacts.org/cgi/search.pl",
          type: "GET",
          data: {
            search_terms: this.id.trim().split("-").join(" "),
            search_simple: "1",
            json: "1",
          },
        })
          .done(function (data, textStatus, jqXHR) {
            console.log("HTTP Request Succeeded: " + jqXHR.status);
            console.log(data);
            if (data["count"] > 0) {
              let products = data["products"];

              _this.upcResults = products;
              _this.selectUpcResult(0);
            }
          })
          .fail(function (jqXHR, textStatus, errorThrown) {
            console.log("HTTP Request Failed");
          })
          .always(function () {
            /* ... */
            _this.upcFetching = false;
          });
      }
    },
    login() {
      /** @type {firebase.auth.Auth} */
      let auth = this.$auth;
      var provider = new firebase.auth.GoogleAuthProvider();
      let _this = this;
      auth
        .signInWithPopup(provider)
        .then(function (result) {
          _this.$toast.success("Logged In");
          //submitToDatabase(_this)
        })
        .catch(function (error) {
          console.log(error);
          _this.$toast.error("You have to be logged in to submit a snack!");
        });
    },
    submitToDatabase(context) {
      //Auth
      /** @type {firebase.auth.Auth} */
      let auth = this.$auth;
      if (auth.currentUser == null) {
        this.login();
        return;
      }

      console.log(
        this.id,
        this.name,
        this.upc,
        this.type,
        this.password,
        this.onlyImage
      );

      let _this = this;

      let id = this.id;
      let name = this.name;
      let upc = Number(this.upc);
      let type = this.type;
      let password = this.password;
      let selectedImage = this.selectedImage;
      let selectedBanner = this.selectedBanner;
      let onlyUpdateImage = this.onlyImage;

      if (
        (id != null &&
          id != "" &&
          !id.includes(" ") &&
          name != null &&
          name != "" &&
          upc != null &&
          typeof upc == "number" &&
          type != null &&
          password != null &&
          password != "" &&
          selectedImage != null &&
          selectedBanner != null) ||
        (id != null &&
          id != "" &&
          selectedBanner != null &&
          selectedImage != null &&
          onlyUpdateImage == true &&
          password != null &&
          password != "")
      ) {
        this.isLoading = true;

        $.ajax({
          type: "POST",
          url: "https://us-central1-snax-dde4e.cloudfunctions.net/addSnacks",
          headers: {
            "Content-Type": "text/plain; charset=utf-8",
          },
          processData: false,
          data: JSON.stringify({
            id: id,
            upc: upc,
            name: name,
            type: type,
            password: password,
            onlyImage: onlyUpdateImage,
          }),
        })
          .done(function (data, textStatus, jqXHR) {
            console.log("HTTP Request Succeeded: " + jqXHR.status);
            let results = JSON.parse(data);
            if (results["status"] == "success") {
              //The request went through
              console.log("success");

              //Get image info
              let refId = results["id"];
              let selectedURL =
                "https:" + _this.imageResults[selectedImage]["thumbnail"];

              //Reset values
              _this.id = null;
              _this.name = null;
              _this.upc = null;
              _this.imageResults = [];
              _this.selectedImage = null;
              _this.upcResults = [];

              _this.$nextTick(() => {
                _this.isLoading = true;
              });

              //Download image
              $.ajax({
                url: selectedURL,
                cache: false,
                xhrFields: {
                  responseType: "blob",
                },
              })
                .done(
                  /** @param {Blob} data */
                  async function (data, textStatus, jqXHR) {
                    /** @type {firebase.storage.Storage} */
                    let storage = _this.$storage;

                    //Render the banner
                    let bannerNode = document.getElementById(
                      "banner-" + selectedBanner
                    );
                    bannerNode.classList.add("s-banner-render");
                    try {
                      var scale = 2;
                      let blob = await domtoimage.toBlob(bannerNode, {
                        width: bannerNode.clientWidth * scale,
                        height: bannerNode.clientHeight * scale,
                        style: {
                          transform: "scale(" + scale + ")",
                          transformOrigin: "top left",
                        },
                      });
                      await storage
                        .ref()
                        .child("snacks-banners/" + refId + ".png")
                        .put(blob);
                    } catch (error) {
                      console.log("banner uplaod fail", error);
                    }

                    //data is a blob

                    //Create ref
                    try {
                      await storage
                        .ref()
                        .child("snacks/" + refId + ".jpg")
                        .put(data);
                    } catch (error) {
                      console.log(err);
                      _this.$toast.warning(
                        "Added Snack (but failed to upload image)"
                      );
                    }

                    _this.$toast.success("Added Snack!");
                    _this.isLoading = false;

                    //reset banner stuff
                    _this.bannerWandOutput = null;
                    _this.bannerColorResults = [];
                    _this.bannerColorSelected = null;
                    _this.selectedBanner = null;
                  }
                )
                .fail(function (jqXHR, textStatus, errorThrown) {
                  console.log("HTTP Request Failed", textStatus);
                  _this.$toast.warning(
                    "Added Snack (but failed to download image)"
                  );
                  _this.isLoading = false;
                });
            } else {
              console.log("not success", results);
              _this.$toast.error(results["error"]);
            }
          })
          .fail(function (jqXHR, textStatus, errorThrown) {
            console.log(textStatus);
            _this.$toast.error("Request failed. Try again later.");
          })
          .always(function () {
            _this.isLoading = false;
          });
      } else {
        console.log("some of the data isnt good", id, name, upc, type);
        //toastr["error"]("Missing one or more required fields.")
        _this.$toast.error("You are missing one or more required fields.");
      }
    },
  },
};
</script>

<style scoped>
.s-image-square {
  width: 80pt;
  height: 80pt;
  margin: 5pt;
  background-color: gainsboro;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}

.s-image-selected {
  border-color: green;
  border-width: 4pt;
  border-style: solid;
}

.s-banner-wand-preview {
  padding: 10pt;
  background-color: white;
  background-image: url("../assets/transparentbg.png");
  background-size: 35pt;
  width: 70pt;
  height: 70pt;
  display: inline-block;
  margin-right: 10pt;
}

.s-banner-wand-preview span {
  position: absolute;
  margin-left: 0pt;
  font-size: 8pt;
  margin-top: -10pt;
  margin-left: -10pt;
  font-weight: bold;
}

.s-banner-color-preview {
  height: 30pt;
  width: 30pt;
  margin-right: 10pt;
  display: inline-block;
}

.s-banner-previews * {
  border-radius: 12pt;
  margin-right: 10pt;
}

.s-banner-selected {
  outline-color: green;
  outline-width: 4pt;
  outline-style: solid;
}

.s-banner-render {
  outline: none;
  border-radius: 0;
  /* transform: scale(4); */
}

.s-card {
  width: 150pt;
  height: 75pt;
  overflow: hidden;
  display: inline-block;
}

.lbr:before {
  position: absolute;
  content: "";
  background-image: url("../assets/snax-watermark.png");
  background-size: 15pt;
  opacity: 0.35;
  background-repeat: no-repeat;
  width: 15pt;
  height: 15pt;
  margin: 58pt 128pt;
}

.lbl:before {
  position: absolute;
  content: "";
  background-image: url("../assets/snax-watermark.png");
  background-size: 15pt;
  opacity: 0.35;
  background-repeat: no-repeat;
  width: 15pt;
  height: 15pt;
  margin: 58pt 6pt;
}
</style>