<template>
  <div class="s-card lbl" :style="{
      'background': `linear-gradient(0deg, ${color[0]} 0%, ${color[1]} 100%)`,
  }">
    <div class="s-card-inner" :style="{
        'background-image': `url(${src})`
    }"
    >
    </div>
  </div>
</template>

<script>
export default {
    props: [ 'src', 'color' ],
    name: "banner1"
}
</script>

<style scoped>
    

    .s-card-inner {
        /* position: absolute; */
    width: 200%;
    height: 200%;
    margin-top: -30%;
    margin-left: -50%;
    background-size: 30pt;
    transform: rotate(30deg);
    }
</style>