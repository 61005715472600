import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "regenerator-runtime";
import 'babel-polyfill';

import * as firebase from "firebase/app";
import "firebase/storage"
import "firebase/auth"

import VueToast from 'vue-toast-notification';
// Import one of available themes
import 'vue-toast-notification/dist/theme-default.css';
//import 'vue-toast-notification/dist/theme-sugar.css';
 
Vue.use(VueToast);
Vue.config.productionTip = false

var firebaseConfig = {
  apiKey: "AIzaSyCjBTFZdfPulDEo7USY7GlIOVKybJgljCQ",
  authDomain: "snax-dde4e.firebaseapp.com",
  databaseURL: "https://snax-dde4e.firebaseio.com",
  projectId: "snax-dde4e",
  storageBucket: "snax-dde4e.appspot.com",
  messagingSenderId: "551974726539",
  appId: "1:551974726539:web:cf747c3223ab303232dd8e",
  measurementId: "G-7GH919EDEN"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

Vue.prototype.$storage = firebase.storage()
Vue.prototype.$auth = firebase.auth()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
