<template>
  <div class="s-card lbr" :style="{
      'background': `linear-gradient(0deg, ${color[0]} 0%, ${color[1]} 100%)`,
  }">
    <div class="s-card-inner" :style="{
        'background-image': `url(${src})`
    }"
    >
    </div>
  </div>
</template>

<script>
export default {
    props: [ 'src', 'color' ],
    name: "banner3"
}
</script>

<style scoped>
    

    .s-card-inner {
        /* position: absolute; */
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 65pt;
    transform: rotate(10deg);
    background-position: 42.5pt 5pt;
    }
</style>