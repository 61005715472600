var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"s-card lbr",style:({
    'background': ("linear-gradient(0deg, " + (_vm.color[0]) + " 0%, " + (_vm.color[1]) + " 100%)"),
})},[_c('div',{staticClass:"s-card-inner",style:({
      'background-image': ("url(" + _vm.src + ")")
  })}),_c('div',{staticClass:"s-card-inner odd",style:({
      'background-image': ("url(" + _vm.src + ")")
  })}),_c('div',{staticClass:"s-card-inner",style:({
      'background-image': ("url(" + _vm.src + ")")
  })}),_c('div',{staticClass:"s-card-inner odd",style:({
      'background-image': ("url(" + _vm.src + ")")
  })}),_c('div',{staticClass:"s-card-inner",style:({
      'background-image': ("url(" + _vm.src + ")")
  })})])}
var staticRenderFns = []

export { render, staticRenderFns }