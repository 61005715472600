<template>
  <div class="s-card lbr" :style="{
      'background': `linear-gradient(0deg, ${color[0]} 0%, ${color[1]} 100%)`,
  }">
    <div class="s-card-inner" :style="{
        'background-image': `url(${src})`
    }"
    />
     <div class="s-card-inner odd" :style="{
        'background-image': `url(${src})`
    }"
    />
    <div class="s-card-inner" :style="{
        'background-image': `url(${src})`
    }"
    />
    <div class="s-card-inner odd" :style="{
        'background-image': `url(${src})`
    }"
    />
    <div class="s-card-inner" :style="{
        'background-image': `url(${src})`
    }"
    />
    

  </div>
</template>

<script>
export default {
    props: [ 'src', 'color' ],
    name: "banner3"
}
</script>

<style scoped>
    

    .s-card-inner {
        /* position: absolute; */
    width: 30pt;
    display: inline-block;
    height: 150%;
    background-repeat:repeat-y;
    background-size: 30pt;
    background-position: 0pt;
    overflow: visible;
    }

    .odd {
        transform: translateY(-15pt)
    }
</style>