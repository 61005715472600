<template>
  <div class="s-card lbl" :style="{
      'background': `linear-gradient(0deg, ${color[0]} 0%, ${color[1]} 100%)`,
  }">
    <div class="s-card-inner" :style="{
        'background-image': `url(${src})`
    }"
    >
    </div>
  </div>
</template>

<script>
export default {
    props: [ 'src', 'color' ],
    name: "banner3"
}
</script>

<style scoped>
    
    .s-card-inner {
        /* position: absolute; */
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 80pt;
    background-position: 35pt 20pt;
    }
</style>